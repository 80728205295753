<template lang="pug">
.nav-align-top
  ul.nav.nav-tabs.nav-fill(role='tablist')
    li.nav-item(role='presentation'): button.nav-link(:class="i == 1 ? 'active' : ''" @click="i = 1") Shop
    li.nav-item(role='presentation'): button.nav-link(:class="i == 2 ? 'active' : ''" @click="i = 2") Produkte
    li.nav-item(role='presentation'): button.nav-link(:class="i == 3 ? 'active' : ''" @click="i = 3") Elemente
    li.nav-item(role='presentation'): button.nav-link(:class="i == 4 ? 'active' : ''" @click="i = 4") Rabatte
    li.nav-item(role='presentation' v-if='dinnershow == "false"'): a.nav-link(:href="'/ticketshops/intern_tickets?id='+id") Interne Tickets
.card.m-0
  .card-header
    a.float-end.mt-1(:href="'/ticketshops/domains/'+id+'/edit'" v-if="i == 1")
      svg(xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"): path(fill="currentColor" d="M12,8A4,4 0 0,1 16,12A4,4 0 0,1 12,16A4,4 0 0,1 8,12A4,4 0 0,1 12,8M12,10A2,2 0 0,0 10,12A2,2 0 0,0 12,14A2,2 0 0,0 14,12A2,2 0 0,0 12,10M10,22C9.75,22 9.54,21.82 9.5,21.58L9.13,18.93C8.5,18.68 7.96,18.34 7.44,17.94L4.95,18.95C4.73,19.03 4.46,18.95 4.34,18.73L2.34,15.27C2.21,15.05 2.27,14.78 2.46,14.63L4.57,12.97L4.5,12L4.57,11L2.46,9.37C2.27,9.22 2.21,8.95 2.34,8.73L4.34,5.27C4.46,5.05 4.73,4.96 4.95,5.05L7.44,6.05C7.96,5.66 8.5,5.32 9.13,5.07L9.5,2.42C9.54,2.18 9.75,2 10,2H14C14.25,2 14.46,2.18 14.5,2.42L14.87,5.07C15.5,5.32 16.04,5.66 16.56,6.05L19.05,5.05C19.27,4.96 19.54,5.05 19.66,5.27L21.66,8.73C21.79,8.95 21.73,9.22 21.54,9.37L19.43,11L19.5,12L19.43,13L21.54,14.63C21.73,14.78 21.79,15.05 21.66,15.27L19.66,18.73C19.54,18.95 19.27,19.04 19.05,18.95L16.56,17.95C16.04,18.34 15.5,18.68 14.87,18.93L14.5,21.58C14.46,21.82 14.25,22 14,22H10M11.25,4L10.88,6.61C9.68,6.86 8.62,7.5 7.85,8.39L5.44,7.35L4.69,8.65L6.8,10.2C6.4,11.37 6.4,12.64 6.8,13.8L4.68,15.36L5.43,16.66L7.86,15.62C8.63,16.5 9.68,17.14 10.87,17.38L11.24,20H12.76L13.13,17.39C14.32,17.14 15.37,16.5 16.14,15.62L18.57,16.66L19.32,15.36L17.2,13.81C17.6,12.64 17.6,11.37 17.2,10.2L19.31,8.65L18.56,7.35L16.15,8.39C15.38,7.5 14.32,6.86 13.12,6.62L12.75,4H11.25Z")
      | Domain Einstellungen
    h4.mb-0 {{title}}
    .text-muted: small(v-if="data && data.dropout_rate") Warenkorb-Abbruchrate: {{data.dropout_rate}} [(1 – Bestellungen / Warenkorbabrufe) * 100]
  .card-body(v-if="data")
    div(v-if="i == 1")
      ul.nav.nav-pills.mb-3.nav-fill.border.border-primary.rounded(role='tablist')
        li.nav-item(role='presentation')
          button.nav-link(:class="ii == 1 ? 'active' : ''" @click="ii = 1; success = false" type='button' role='tab' data-bs-toggle='tab' data-bs-target='#navs-pills-justified-home' aria-controls='navs-pills-justified-home' aria-selected='true') Einstellungen
        li.nav-item(role='presentation')
          button.nav-link(:class="ii == 2 ? 'active' : ''" @click="ii = 2; success = false" type='button' role='tab' data-bs-toggle='tab' data-bs-target='#navs-pills-justified-profile' aria-controls='navs-pills-justified-profile' aria-selected='false' tabindex='-1') Vorverkaufstellen
        li.nav-item(role='presentation')
          button.nav-link(:class="ii == 3 ? 'active' : ''" @click="ii = 3; success = false" type='button' role='tab' data-bs-toggle='tab' data-bs-target='#navs-pills-justified-messages' aria-controls='navs-pills-justified-messages' aria-selected='false' tabindex='-1') Allgemeine Infos
      .alert.alert-success(v-if="success") Daten wurden gespeichert
      active-form(:fields="domainFields" :errors="errors" v-model="domain" name="domain" :domain="data")
      .text-center: button.btn.btn-primary.btn-lg.mt-5(type="button" @click="save" :class="{disabled: loading}")
        span.spinner-border.spinner-border-sm(style="margin-right: 10px;" v-if="loading")
        | Einstellungen speichern
    active-table(v-if="i == 2" :elements="data.products" :fields="products" title="Produkt" id="products" v-model="data")
    active-table(v-if="i == 3" :elements="data.page_items" :fields="pageItems" title="Element" id="page_items" v-model="data")
    active-table(v-if="i == 4" :elements="data.discounts" :fields="discounts" title="Rabatt" id="discounts" v-model="data")
</template>

<script>
import ActiveTable from './ActiveTable.vue'
export default {
  components: {
    ActiveTable
  },
  props: [
    "_i",
    "title",
    "countries",
    "dinnershow"
  ],
  data () {
    return {
      i: 1,
      ii: 1,
      data: null,
      domain: {},
      errors: {},
      success: false,
      loading: false
    }
  },
  mounted () {
    this.i = this._i || 1
    this.fetch()
  },
  watch: {
  },
  computed: {
    id(){
      return window.location.href.split("?")[0].split("/").slice(-1)[0]
    },
    domainFields(){

      const domainSettings = [
          {title: "Name", type: "text", id: "name", col: 4},
          {title: "Domain", type: "text", id: "domain_name", col: 4},
          {title: "Original Domain", type: "text", id: "original_domain_name", col: 4},
          {title: "Bechreibung", type: "text", id: "description", col: 12},
          {title: "Keywords", type: "text", id: "keywords", col: 12},
          {title: "VVK Gebühr", type: "number", id: "advance_booking_fee", col: 6},
          {title: "Versandkosten", type: "number", id: "shipping_fee", col: 6},
          {title: "Logo", type: "file", id: "logo", col: 6},
          {title: "Email Logo", type: "file", id: "mail_logo", col: 6},
          {title: "Hotline", type: "text", id: "hotline", col: 4},
          {title: "Instagram", type: "text", id: "instagram", col: 4},
          {title: "Facebook", type: "text", id: "facebook", col: 4},
          {title: "Veranstaltungsort", type: "text", id: "location", col: 6},
          {title: "Veranstalter", type: "text", id: "organizer", col: 6},
          {title: "Rechtlicher Hinweis", type: "text", id: "legal_notice", col: 8},
          {title: "Datenschutz URL", type: "text", id: "data_protection_url", col: 4},
          {title: "Empfehlung 1 URL", type: "text", id: "recommendation_url_1", col: 4},
          {title: "Empfehlung 1 Bild", type: "file", id: "recommendation_image_1", col: 4, hint: "500 × 281 Px, bei max 85% JPG Qualität"},
          {title: "Empfehlung 1 Title", type: "text", id: "recommendation_title_1", col: 4},
          {title: "Empfehlung 2 URL", type: "text", id: "recommendation_url_2", col: 4},
          {title: "Empfehlung 2 Bild", type: "file", id: "recommendation_image_2", col: 4, hint: "500 × 281 Px, bei max 85% JPG Qualität"},
          {title: "Empfehlung 2 Title", type: "text", id: "recommendation_title_2", col: 4},
          {title: "Eingänge", type: "text", id: "entrances", col: 12},
          {title: "Rechnung Event Logo", type: "file", id: "invoice_event_logo", col: 6},
          {title: "Rechnung Veranstalter Logo", type: "file", id: "invoice_company_logo", col: 6},
          {title: "Bankdaten", type: "text", id: "bank_account", col: 12},
          {title: "SMTP Server", type: "text", id: "smtp_address", col: 4},
          {title: "SMTP Benutzername", type: "text", id: "smtp_user_name", col: 4},
          {title: "SMTP Passwort", type: "text", id: "smtp_password", col: 4},
          {title: "Email Info", type: "long", id: "email_info", col: 12},
          {title: "Secupay Vertrags ID", type: "text", id: "contract_id", col: 12},
          {title: "Header Button URL", type: "text", id: "header_button_url", col: 6},
          {title: "Header Button HTML", type: "text", id: "header_button_html", col: 6},
          {title: "Styling (CSS)", type: "long", id: "css", col: 12},
          {title: "Fav Icon", type: "file", id: "fav_icon", col: 12},
          {title: "GP Hintergrundbild", type: "file", id: "drinks_flat_rate_image", col: 6},
          {title: "BG Hintergrundbild", type: "file", id: "welcome_drinks_image", col: 6},
          {title: "Menü Hintergrundbild", type: "file", id: "menu_image", col: 6},
          {title: "Vegan Hintergrundbild", type: "file", id: "vegan_image", col: 6},
          {title: "Catering Info", type: "long", id: "catering", col: 6},
          {title: "Catering Footer", type: "long", id: "catering_legal_notice", col: 6},
          {title: "Catering Logo", type: "file", id: "invoice_catering_logo", col: 6},
          {title: "Catering Rechnungs-Email", type: "text", id: "catering_invoice_email", col: 6},
          {title: "Nummernkreis Beginn", type: "text", id: "start_number", col: 12},
          {title: "Kontakt Title", type: "long", id: "contact_title", col: 4},
          {title: "Kontakt Text", type: "long", id: "contact_text", col: 4},
          {title: "Kontakt Betreffs", type: "long", id: "contact_subjects", col: 4},
          {title: "Header Links", type: "nastedForm", id: "header_links", col: 12, fields: [
            {title: "Titel", type: "text", id: "title", col: 6},
            {title: "Link", type: "text", id: "link", col: 6},
          ]},
          {title: "Footer 1 Links", type: "nastedForm", id: "footer_links1", col: 12, fields: [
            {title: "Titel", type: "text", id: "title", col: 6},
            {title: "Link", type: "text", id: "link", col: 6},
          ]},
          {title: "Footer 2 Links", type: "nastedForm", id: "footer_links2", col: 12, fields: [
            {title: "Titel", type: "text", id: "title", col: 6},
            {title: "Link", type: "text", id: "link", col: 6},
          ]},
      ]

      const advanceSalesStands = [
        {title: "Vorverkaufstellen", type: "nastedForm", id: "advance_sales_stands", col: 12, fields: [
          {title: "Name", type: "text", id: "name", col: 3},
          {title: "Straße", type: "text", id: "street", col: 3},
          {title: "PLZ", type: "text", id: "zip", col: 3},
          {title: "Stadt", type: "text", id: "city", col: 3},
        ]}
      ]

      const generalInformations = [
        {title: "Allgemeine Infos", type: "nastedForm", id: "general_informations", col: 12, fields: [
          {title: "Title", type: "text", id: "title", col: 3},
          {title: "Inhalt", type: "long", id: "body", col: 9},
        ]}
      ]

      if (this.ii == 1) return domainSettings
      if (this.ii == 2) return advanceSalesStands
      if (this.ii == 3) return generalInformations
      return []
    },
    pageItems(){
      return [
        {title: "Type", type: "select", id: "item_type", col: 6, th: true, options: {
          tickets1: "Einfache Ticketauswahl",
          tickets2: "Tickets & Termine",
          ticketshop: "Ticketshop",
          voucher: "Gutschein",
          faq: "Allgemeine Infos",
          faq2: "Ticketpreise",
          time_schedule: "Zeitplan",
          card1: "Kachel mit Bild",
          cards: "Kacheln",
          sales_stands: "Vorverkaufstellen",
          outlook: "Ausblick"
        }},
        {title: "Deaktiviert", type: "boolean", id: "unavailable", col: 3, th: true},
        {title: "Reinfolge", type: "number", id: "order", col: 3},
        {title: "Überschift 1", type: "text", id: "h1", col: 6},
        {title: "Text 1", type: "long", id: "p1", col: 6},
        {title: "Bild 1", type: "file", id: "i1", col: 6},
        {title: "Link 1", type: "text", id: "l1", col: 6},
        {title: "Überschift 2", type: "text", id: "h2", col: 6},
        {title: "Text 2", type: "long", id: "p2", col: 6},
        {title: "Bild 2", type: "file", id: "i2", col: 6},
        {title: "Link 2", type: "text", id: "l2", col: 6},
        {title: "Überschift 3", type: "text", id: "h3", col: 6},
        {title: "Text 3", type: "long", id: "p3", col: 6},
        {title: "Bild 3", type: "file", id: "i3", col: 6},
        {title: "Link 3", type: "text", id: "l3", col: 6},
        {title: "Überschift 5", type: "text", id: "h4", col: 6},
        {title: "Text 4", type: "long", id: "p4", col: 6},
        {title: "Bild 4", type: "file", id: "i4", col: 6},
        {title: "Link 4", type: "text", id: "l4", col: 6},
        {title: "Überschift 5", type: "text", id: "h5", col: 6},
        {title: "Text 5", type: "long", id: "p5", col: 6},
        {title: "Bild 5", type: "file", id: "i5", col: 6},
        {title: "Link 5", type: "text", id: "l5", col: 6},
        {title: "Überschift 6", type: "text", id: "h6", col: 6},
        {title: "Text 6", type: "long", id: "p5", col: 6},
        {title: "Bild 6", type: "file", id: "i5", col: 6},
        {title: "Link 6", type: "text", id: "l5", col: 6},
      ]
    },
    products(){
      return [
        {title: "Name", type: "text", id: "name", col: 12, th: true},
        {title: "Deaktiviert", type: "boolean", id: "unavailable", col: 3, th: true},
        {title: "Frühbucherpreis", type: "boolean", id: "earlybird", col: 3, th: true},
        {title: "Geschenkgutschein", type: "boolean", id: "hardticket", col: 3, th: true},
        {title: "VIP", type: "boolean", id: "vip", col: 3, th: true},
        {title: "Preis", type: "number", id: "price", col: 6, th: true, postfix(form){return '€'}},
        {title: "Steuer", type: "number", id: "tax", col: 6, postfix(form){return '%'}},
        {title: "Ticket", type: "file", id: "head_image", col: 6},
        {title: "Lageplan", type: "file", id: "map_image", col: 6},
        {title: "Beschreibung", type: "text", id: "description", col: 12},
        {title: "Eingänge", type: "text", id: "entrances", col: 12},
        {title: "Datum", type: "date", id: "event_date", col: 4},
        {title: "Einlass", type: "time", id: "admission_time", col: 4},
        {title: "Beginn", type: "time", id: "start_time", col: 4},
        {title: "Ticket Footer", type: "long", id: "footer", col: 12},
        {title: "Vorteile", type: "nastedForm", id: "advantages", col: 12, fields: [
          {title: "Title", type: "text", id: "title", col: 12},
        ]}
      ]
    },
    discounts(){
      const products = this.data.product_names.filter(x => x.name).map(x => {
        let name = x.name
        if (x.hardticket){
          name += " (Geschenkgutschein)"
        }
        return {label: name, id: x.id}
      })
      return [
        {title: "Code", type: "text", id: "code", th: true, col: 12},
        {title: "Art", type: "select", id: "discount_typ", options: {absolute: "Absolut in €", percentage: "Prozentual in %"}, th: true, col: 6},
        {title: "Wert", type: "number", id: "discount_value", th: true, col: 6, postfix(form){return form.discount_typ == 'absolute' ? '€' : '%'}},
        {title: "Limit an Einlösungen", type: "number", id: "limit", col: 3},
        {title: "Vorverkaufsgebühr", type: "number", id: "advance_booking_fee", col: 3, hint: "Wenn leer, Standard VVK"},
        {title: "Nur auf diese Produkte anwenden", type: "multiple", id: "product_ids", options: products, col: 6},
        {title: "Start Datum", type: "datetime-local", id: "start_at", col: 6},
        {title: "End Datum", type: "datetime-local", id: "end_at", col: 6},
        {title: "Rabatt pro Produkt und Anzahl anwenden", type: "boolean", id: "per_product", col: 6},
        {title: "Als Kontigent buchen", type: "boolean", id: "quota", col: 6},
        {title: "Anrede", type: "select", id: "salutation", options: {male: "Herr", female: "Frau"}, col: 6, class(form){return form.quota ? "" : "d-none"}},
        {title: "Firma", type: "text", id: "company", col: 6, class(form){return form.quota ? "" : "d-none"}},
        {title: "Vorname", type: "text", id: "firstname", col: 6, class(form){return form.quota ? "" : "d-none"}},
        {title: "Nachname", type: "text", id: "lastname", col: 6, class(form){return form.quota ? "" : "d-none"}},
        {title: "E-Mail", type: "text", id: "email", col: 6, class(form){return form.quota ? "" : "d-none"}},
        {title: "Telefon", type: "text", id: "phone", col: 6, class(form){return form.quota ? "" : "d-none"}},
        {title: "Straße", type: "text", id: "street", col: 9, class(form){return form.quota ? "" : "d-none"}},
        {title: "Haus-Nr", type: "text", id: "street_number", col: 3, class(form){return form.quota ? "" : "d-none"}},
        {title: "PLZ", type: "text", id: "zip", col: 4, class(form){return form.quota ? "" : "d-none"}},
        {title: "Stadt", type: "text", id: "city", col: 4, class(form){return form.quota ? "" : "d-none"}},
        {title: "Land", type: "select", id: "country", options: JSON.parse(this.countries), col: 4, class(form){return form.quota ? "" : "d-none"}},
      ]
    }
  },
  methods: {
    fetch(){
      fetch(window.location.href.split("?")[0]+".json").then(x => x.json()).then((data) => {
        this.data = data
        this.domain = data.domain
      })
    },
    save(){
      this.loading = true
      this.success = false
      const csrfToken = document.getElementsByName("csrf-token")[0].content
      let options = {
        method: "PATCH",
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      }
      let params = {data: {}}
      let form = JSON.parse(JSON.stringify(this.domain))
      this.domainFields.filter(x => x.type == 'multiple').map(e => form[e.id] = form[e.id] ? form[e.id].map(x => x.id) : [])

      params.data.domain = form

      options.body = JSON.stringify(params)

      fetch(window.location.href.split("?")[0]+".json", options).then(x => x.json()).then((data) => {
        window.scrollTo(0,0)
        this.loading = false
        this.data = data.data
        if (!data.errors.domain || data.errors.domain.length == 0){
          this.errors = {}
          this.success = true
        } else {
          data.errors.domain.map( e => {
            this.errors = {...e}
          })
        }
      })
    }


  }
}
</script>

<style lang="scss">
</style>