<template lang="pug">
div
  kitchen-screen(v-if="screen_id && screen_id.startsWith('screen_3')" :screen_id="screen_id" :_data="data")
  color-boocking(v-else-if="ipad==='booking'" :_data="data")
  gastro(v-else-if="ipad==='true'" :data="data" :screen_id="screen_id")
  .card-body(v-else)
    #no-more-tables: table.table
      thead
        tr
          th Name
          th Anzahl
          th Tisch(e)
          th Kommentar
          th(style="width: 200px;" v-if="read_only=='false' && ipad!='service'") CHECK-IN
          th(v-if="read_only=='false'") Zusatz buchen
      tbody(v-for="p in persons")
        tr(:style="{backgroundColor: backgroundColor(p)}")
          td(data-title="Name")
            svg(xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="heigh: 10px; width: 10px; margin-top:-2px; margin-right: 5px;" v-if="ipad!='service'"): path(d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" :fill="!p.full_paid ? '#ff9000' : p.order_state == 'reserviert' ? '#ff0000' : '#00ff00'")
            a(v-if="p.order_id" :href="'/ticketshops/orders/'+p.order_id" v-html="p.name")
            span(v-else v-html="p.name")
          td(data-title="Anzahl") {{p.checkin_count}} / {{p.count}} ({{p.checkin_count-p.count}})
          td(data-title="Tisch(e)") {{p.tables}}
          td(data-title="Kommentar")
            span(v-if="p.color != '#ffffff'" :style="{color: p.color}")   █  
            strong(v-if="p.welcome_drink") BG 
            strong(v-if="p.drinks_flat_rate") GP 
            strong(v-if="p.vegan_count != 0") {{p.vegan_count}}x Vegan 
            div 
              | {{fullComment(p)}} {{p.kitchen_comment}}
            div(v-if="p.category != p.fixed_category"): strong: i ehem. {{p.fixed_category}}, nun {{p.category}}
          td.text-center(v-if="read_only=='false' && ipad!='service'" style="width: 200px;" data-title="CHECK-IN")
            .input-group.input-group-lg
              input(class="form-control" type="number" :value="p.temp_checkin_count" @input="(x) => updateCount(p, x.target.value, 'temp_checkin_count')" @keyup.enter="addCount(p)")
              button.btn.btn-primary(type="button" @click="addCount(p)")
                svg(xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="width: 24px; height: 24px;"): path(fill="currentColor" d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z")
          td(v-if="read_only=='false'" data-title="Zusatz buchen"): div: button.btn.btn-info.btn-lg(type="button" style="width: 100%;" @click="openModal(p)"): svg(xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="width: 24px; height: 24px;"): path(fill="currentColor" d="M18 15V18H15V20H18V23H20V20H23V18H20V15H18M19.5 3.5L18 2L16.5 3.5L15 2L13.5 3.5L12 2L10.5 3.5L9 2L7.5 3.5L6 2L4.5 3.5L3 2V22L4.5 20.5L6 22L7.5 20.5L9 22L10.5 20.5L12 22L13.26 20.74C13.09 20.18 13 19.59 13 19C13 18.32 13.12 17.64 13.34 17H6V15H14.53C15.67 13.73 17.29 13 19 13C19.68 13 20.36 13.12 21 13.34V2L19.5 3.5M18 13H6V11H18V13M18 9H6V7H18V9Z")
        tr(:style="{backgroundColor: 'rgb(233 233 233)', color: '#ff0000', fontWeight: 500}" v-if="p.current_comment && p.current_comment != p.comment"): td(style="paddingLeft: 10px;" colspan="6" v-html="p.current_comment") 
    #modalList.modal.fade(tabindex="-1", aria-hidden="true")
      .modal-dialog
        .modal-content
          .modal-header
            div
              h1.modal-title.fs-4
                svg(xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="heigh: 20px; width: 20px; margin-top:-5px; margin-right: 5px;" v-if="ipad!='service'"): path(d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" :fill="!currentTicket.full_paid ? '#ff9000' : currentTicket.order_state == 'reserviert' ? '#ff0000' : '#00ff00'")
                span(v-html="currentTicket.name")
            button.btn-close(type="button", data-bs-dismiss="modal", aria-label="Close")
          .modal-body
            .mb-4(style="margin-top: -20px;")
              small K-Nr.: {{currentTicket.customer_number}}   Auf-Nr.: {{currentTicket.order_number}}  Anzahl: {{currentTicket.count}} Tische: {{currentTicket.tables}}
              div(v-if="currentTicket.comment && currentTicket.comment.length != 0"): small {{currentTicket.comment}}
            .mb-5
              label.form-label(for="wardrobe") Garderobeanzahl und Ansprechpartner
              input.form-control#wardrobe(type="text", placeholder="Garderobeanzahl und Ansprechpartner", v-model="wardrobeCount")
            .mb-3
              label.form-label(for="gastro_comment") Gastro Kommentar
              input.form-control#gastro_comment(type="text", placeholder="Gastro Kommentar", v-model="comment")
            .mb-3
              label.form-label(for="kitchen_comment") Küchen Kommentar
              input.form-control#kitchen_comment(type="text", placeholder="Küchen Kommentar", v-model="kitchenComment")

            .mb-3
              label.form-label(for="count") Gastro Anzahl
              input.form-control#count(type="number", placeholder="Gastro Anzahl", v-model="count")

            .mb-3
              label.form-label(for="vegancount") Vegan Anzahl
              input.form-control#vegancount(type="number", placeholder="Anzahl", v-model="veganCount")
            .mb-3.form-check.form-switch
              input.form-check-input#welcomeDrink(type="checkbox", v-model="welcomeDrink")
              label.form-check-label(for="welcomeDrink") Begrüßungsgetränk (BG)
            .mb-3.form-check.form-switch
              input.form-check-input#drinksFlatRate(type="checkbox", v-model="drinksFlatRate")
              label.form-check-label(for="drinksFlatRate") Getränkepauschale (GP)

            .mb-3.form-check.form-switch
              input.form-check-input#sendInvoice(type="checkbox", v-model="sendInvoice")
              label.form-check-label(for="sendInvoice") Rechnung zusenden
            .mb-3
              label.form-label(for="color") Bändchenfarbe
              input.form-control#color(placeholder="Bändchenfarbe", v-model="color", type="color" style="height: 35px;")

          .modal-footer
            button.btn.btn-secondary(type="button", data-bs-dismiss="modal") Abbrechen
            button.btn.btn-primary(type="button" @click="updateTicket()", data-bs-dismiss="modal") Speichern

</template>

<script>
import Gastro from "./Gastro.vue"
import ColorBoocking from "./ColorBoocking.vue"
import KitchenScreen from "./KitchenScreen.vue"


export default {
  components: {
    Gastro,
    ColorBoocking,
    KitchenScreen
  },
  props: [
    "data",
    "ipad",
    "read_only",
    "screen_id",
    "color_boocking"
  ],
  data () {
    return {
      code: "",
      currentTicket: {},
      myData: {},
      count: 0,
      veganCount: 0,
      kitchenComment: "",
      comment: "",
      welcomeDrink: false,
      drinksFlatRate: false,
      wardrobeCount: 0,
      sendInvoice: false,
      color: "#ffffff"
    }
  },
  mounted () {
    this.myData = this.data
  },
  watch: {
  },
  computed: {
    persons(){
      const searchFields = ["id", "name", "customer_number", "order_number", "email", "current_comment", "comment", "kitchen_comment"]
      let tickets = this.myData.tickets
      if (!tickets) return []

      if (this.data.search){
        tickets = tickets.filter(ticket => {
          return searchFields.map((x) => {
            return ticket && ticket[x] && this.data.search.split(" ").filter(q => q && q.length != 0).map((q) => {
              return String(ticket[x]).toLowerCase().indexOf(q.toLowerCase()) != -1
            }).indexOf(true) != -1
          }).indexOf(true) != -1
        })
      } 

      const group = this.groupBy(tickets, "customer_number")
      const sortBy = "name"
      return Object.keys(group).map(x => {
        const tickets = group[x]
        let data = {
          id: tickets[0].id,
          name: tickets[0].name,
          order_number: tickets[0].order_number,
          customer_number: tickets[0].customer_number,
          count: tickets.length,
          temp_checkin_count: tickets.length-tickets[0].checkin_count,
          tables: Object.keys(this.groupBy(tickets, "table_number")).join(", "),
          comment: tickets[0].comment,
          welcome_drink: tickets[0].welcome_drink,
          drinks_flat_rate: tickets[0].drinks_flat_rate,
          checkin_count: tickets[0].checkin_count,
          checkedin: tickets[0].checkedin,
          friends: tickets[0].friends,
          gastro_comment: tickets[0].gastro_comment,
          kitchen_comment: tickets[0].kitchen_comment,
          gastro_count: tickets[0].gastro_count,
          wardrobe_count: tickets[0].wardrobe_count,
          send_invoice: tickets[0].send_invoice,
          vegan_count: tickets.filter(x => x.vegan).length,
          order_state: tickets[0].order_state,
          category: tickets[0].category,
          color: tickets[0].color,
          fixed_category: tickets[0].fixed_category,
          current_comment: tickets[0].current_comment,
          kitchen_comment: tickets[0].kitchen_comment,
          order_id: tickets[0].order_id,
          full_paid: tickets.length == tickets.filter(x => x.order_state == tickets[0].order_state).length,
          isDowngrade: tickets.map(e => this.isDowngrade(e)).indexOf(true) != -1
        }

        if (tickets[0].vegan_count || tickets[0].vegan_count === 0 ) {
          data.vegan_count = tickets[0].vegan_count
        }
        return data
      }).sort((a, b) => {
        if (a[sortBy] < b[sortBy])
          return -1;
        if (a[sortBy] > b[sortBy])
          return 1;
        return 0;
      })
    }
  },
  methods: {
    isDowngrade(e){
      if (!e.fixed_category) return false
      if (!e.category) return false

      let fixed_category = parseInt(e.fixed_category.replace("K", "")) || 0
      if (fixed_category == "BT") fixed_category = 0

      let category = parseInt(e.category.replace("K", "")) || 0
      if (category == "BT") category = 0

      if (fixed_category < category){
        return true
      }
      return false
    },
    backgroundColor(p){
      if (p.isDowngrade) return '#ff000052'
      if (p.friends) return '#99d9ff'
    },
    fullComment(t){
      return [...new Set(this.myData.tickets.filter(x => x.customer_number == t.customer_number).map(x => x.comment))].join("; ")
    },
    updateTicket(){
      this.currentTicket.gastro_count = this.count
      this.currentTicket.gastro_comment = this.comment
      this.currentTicket.kitchen_comment = this.kitchenComment
      this.currentTicket.welcome_drink = this.welcomeDrink
      this.currentTicket.drinks_flat_rate = this.drinksFlatRate
      this.currentTicket.send_invoice = this.sendInvoice
      this.currentTicket.vegan_count = this.veganCount
      this.currentTicket.wardrobe_count = this.wardrobeCount
      this.currentTicket.color = this.color
      this.$emit("update", this.currentTicket, {gastro_push: true})
    },
    openModal(p){
      this.currentTicket = p
      this.count = p.gastro_count || p.count
      this.comment = p.gastro_comment
      this.kitchenComment = p.kitchen_comment
      this.welcomeDrink = p.welcome_drink
      this.drinksFlatRate = p.drinks_flat_rate
      this.wardrobeCount = p.wardrobe_count || 0
      this.sendInvoice = p.send_invoice
      this.veganCount = p.vegan_count
      this.color = p.color

      const modalListElement = document.getElementById('modalList')
      const modalList = new bootstrap.Modal(modalListElement)
      modalList.show()
    },
    groupBy(xs, key) {
      return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
    updateCount(ticket, value, type){
      ticket[type] = value
    },
    addCount(ticket){
      ticket.checkin_count = ticket.checkin_count+parseInt(ticket.temp_checkin_count)
      this.$emit("update", ticket)
    },
  }
}
</script>

<style lang="scss">
</style>